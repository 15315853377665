import {buildCollection, EntityCollection} from '@firecms/core';

// this collection is used only to be referenced from offerApplications
const offersCollection: EntityCollection = buildCollection({
  name: 'Offers',
  id: 'offers',
  singularName: 'Offer',
  path: 'offers',
  properties: {},
});

export {offersCollection};

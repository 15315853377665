import {buildCollection, buildProperty, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {PositionType, SkillType} from '../../model/skill';

const SKILL_TYPE_FILTER_MAPPING: {[key in string]?: SkillType} = {
  [SkillType.PROGRAMMING_LANGUAGE]: SkillType.POSITION,
  [SkillType.TECHNOLOGY]: SkillType.PROGRAMMING_LANGUAGE,
};

const childrenCollection = buildCollection({
  name: 'Children',
  path: 'children',
  id: 'children',
  exportable: false,
  inlineEditing: false,
  permissions: {
    create: false,
    edit: false,
    delete: false,
  },
  properties: {
    ref: {
      path: 'skills',
      name: 'Reference',
      editable: false,
      validation: {required: false},
      previewProperties: ['name'],
      dataType: 'reference',
    },
    name: {
      name: 'Name',
      validation: {required: true},
      dataType: 'string',
    },
    type: {
      name: 'Type',
      validation: {required: true},
      dataType: 'string',
      enumValues: SkillType,
    },
    position: {
      name: 'Position',
      validation: {required: false},
      dataType: 'string',
      enumValues: PositionType,
    },
    disabled: {
      name: 'Disabled',
      dataType: 'boolean',
    },
  },
});

const nerSubCollection = buildCollection({
  name: 'NER',
  path: 'ner',
  id: 'ner',
  inlineEditing: true,
  customId: true,
  exportable: false,
  defaultSize: 'xs',
  permissions: {
    edit: true,
    delete: false,
    create: true,
  },
  properties: {
    disabled: {
      dataType: 'boolean',
    },
    patterns: {
      name: 'NER Patterns',
      dataType: 'array',
      of: {
        dataType: 'string',
      },
    },
  },
});

const skillCollection: Omit<EntityCollection, 'id' | 'name'> = {
  defaultSize: 'xs',
  exportable: false,
  selectionEnabled: false,
  inlineEditing: false,
  group: 'Skills',
  path: 'skills',
  subcollections: [childrenCollection, nerSubCollection],
  properties: {
    name: {
      name: 'Name',
      validation: {required: true},
      dataType: 'string',
    },
    position: {
      name: 'Position',
      validation: {required: false},
      dataType: 'string',
      enumValues: PositionType,
    },
    type: {
      name: 'Type',
      hideFromCollection: true,
      validation: {required: true},
      dataType: 'string',
      enumValues: SkillType,
    },
    order: {
      name: 'Order',
      validation: {required: false},
      dataType: 'number',
    },
    parents: buildProperty(val => {
      let data: ReturnType<typeof buildProperty> = {
        name: 'Parents',
        validation: {required: false},
        dataType: 'array',
        of: {
          dataType: 'reference',
          path: 'skills',
          previewProperties: ['name'],
        },
      };

      const type = SKILL_TYPE_FILTER_MAPPING[val.values.type];

      if (type) {
        data = merge(data, {
          of: {
            forceFilter: {
              type: ['==', type],
            },
            dataType: 'reference',
            path: 'skills',
            previewProperties: ['name'],
          },
        });
      }

      return data;
    }),
    disabled: {
      name: 'Disabled',
      dataType: 'boolean',
    },
  },
};

export {childrenCollection, nerSubCollection, skillCollection};

import {buildCollection, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {SkillType} from '../../model/skill';

import {skillCollection} from './common';

const collection: Omit<EntityCollection, 'path' | 'properties'> = {
  name: 'Databases',
  id: 'databases',
  group: 'General',
  singularName: 'Database',
  icon: 'Storage',
  description: 'MySQL, Firestore, MongoDB ...',
  forceFilter: {
    type: ['==', SkillType.DATABASE],
  },
};

const databasesCollection = buildCollection(merge(collection, skillCollection));

export {databasesCollection};

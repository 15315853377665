export enum SkillType {
  PROGRAMMING_LANGUAGE = 'PROGRAMMING_LANGUAGE',
  DATABASE = 'DATABASE',
  CLOUD_PLATFORM = 'CLOUD_PLATFORM',
  TECHNOLOGY = 'TECHNOLOGY',
  TOOL = 'TOOL',
  IDE = 'IDE',
  ASYNCHRONOUS_TOOL = 'ASYNCHRONOUS_TOOL',
  SYNCHRONOUS_TOOL = 'SYNCHRONOUS_TOOL',
  POSITION = 'POSITION',
  LANGUAGE = 'LANGUAGE',
}

export enum PositionType {
  FRONTEND_DEVELOPER = 'FRONTEND_DEVELOPER',
  BACKEND_DEVELOPER = 'BACKEND_DEVELOPER',
  FULL_STACK_DEVELOPER = 'FULL_STACK_DEVELOPER',
  IOS_DEVELOPER = 'IOS_DEVELOPER',
  ANDROID_DEVELOPER = 'ANDROID_DEVELOPER',
  MACHINE_LEARNING_ENGINEER = 'MACHINE_LEARNING_ENGINEER',
  DEV_OPS = 'DEV_OPS',
  DATA_SCIENTIST = 'DATA_SCIENTIST',
  SOFTWARE_ENGINEER = 'SOFTWARE_ENGINEER',
  'NET_DEVELOPER' = 'NET_DEVELOPER',
  'PHP_DEVELOPER' = 'PHP_DEVELOPER',
  'JAVA_DEVELOPER' = 'JAVA_DEVELOPER',
  'QA_ENGINEER' = 'QA_ENGINEER',
  'TESTER' = 'TESTER',
  MOBILE_DEVELOPER = 'MOBILE_DEVELOPER',
}

import {
  buildCollection,
  buildEntityCallbacks,
  DataSourceDelegate,
} from '@firecms/core';
import {offerApplicationsCollection} from './offerApplicationsCollection';

export const applyStatsCollectionBuilder = async ({
  dataSource,
}: {
  dataSource: DataSourceDelegate;
}) => {
  let allApplications = await dataSource.fetchCollection({
    path: 'offerApplications',
    collection: offerApplicationsCollection,
  });

  return buildCollection({
    id: 'applystats',
    path: 'countries',
    name: 'Apply Stats',
    group: 'Views',
    callbacks: buildEntityCallbacks({
      onFetch: async ({entity}) => {
        // get all applications of this country
        const countryApplications = allApplications.filter(
          f => f.values.offer?.city?.countryCode === entity.values.code,
        );
        // calculate number of unique users
        const uniqueUsers = new Set(
          countryApplications.map(s => s?.values.user?.id),
        );

        entity.values.applications = countryApplications.length;
        entity.values.appliedUsers = uniqueUsers.size;
        return entity;
      },
    }),
    properties: {
      name: {
        dataType: 'string',
        name: 'Name',
      },
      applications: {
        dataType: 'number',
        name: 'Total Applications',
      },
      appliedUsers: {
        dataType: 'number',
        name: 'Users Applied',
      },
    },
  });
};

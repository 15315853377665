import {PropertyPreviewProps} from '@firecms/core';
import {Box} from '@mui/material';
import randomColor from 'randomcolor';
import {
  Bar,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

function Charts(
  props: PropertyPreviewProps<
    {
      name: string;
      value: number;
    }[]
  >,
) {
  const data = props.value.filter(item => item.value > 0);

  return (
    <Box width={520} height={359}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          layout="vertical"
          data={data}
          margin={{
            top: 0,
            right: 32,
            bottom: 0,
            left: 0,
          }}>
          <XAxis display="none" type="number" />
          <YAxis
            dataKey="name"
            minTickGap={0}
            strokeWidth={0}
            width={100}
            fontSize={5}
            tick={{
              fill: 'black',
            }}
            type="category"
          />
          <Bar
            barSize={30}
            dataKey="value"
            label={{
              position: 'right',
              fill: 'black',
              fontSize: 4,
            }}>
            {data.map(entry => (
              <Cell
                key={`${entry.name}${entry.value}`}
                fill={randomColor({
                  luminosity: 'light',
                })}
              />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>{' '}
    </Box>
  );
}

export {Charts};

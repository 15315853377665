import {buildCollection, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {SkillType} from '../../model/skill';

import {skillCollection} from './common';

const collection: Omit<EntityCollection, 'path' | 'properties'> = {
  name: 'Tools',
  id: 'tools',
  group: 'General',
  singularName: 'Tool',
  icon: 'Build',
  permissions: {
    edit: false,
  },
  description: 'Docker, Yarn, npm ...',
  forceFilter: {
    type: ['==', SkillType.TOOL],
  },
};

const toolsCollection = buildCollection(merge(collection, skillCollection));

export {toolsCollection};

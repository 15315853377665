import {buildCollection, EntityCollection} from '@firecms/core';

const usersCollection: EntityCollection = buildCollection({
  name: 'Users',
  id: 'users',
  singularName: 'User',
  path: 'users',
  description: 'Registered users data',
  group: 'Users',
  icon: 'Person',
  selectionEnabled: false,
  exportable: false,
  defaultSize: 's',
  initialSort: ['created', 'desc'],
  permissions: {
    edit: false,
  },
  subcollections: [
    buildCollection({
      path: 'skills',
      id: 'skills',
      name: 'Skills',
      permissions: {
        edit: false,
        create: false,
        delete: false,
        read: true,
      },
      exportable: false,
      selectionEnabled: false,
      defaultSize: 'xs',
      properties: {
        name: {
          name: 'Name',
          dataType: 'string',
        },
        type: {
          name: 'Type',
          dataType: 'string',
        },
        value: {
          name: 'Value',
          dataType: 'number',
        },
      },
    }),
  ],
  properties: {
    created: {
      name: 'Created',
      dataType: 'date',
    },
    cityToCompare: {
      name: 'City',
      hideFromCollection: true,
      dataType: 'map',
      properties: {
        name: {
          dataType: 'string',
        },
      },
    },
    email: {
      name: 'E-mail',
      dataType: 'string',
    },
    name: {
      name: 'Name',
      dataType: 'string',
    },
    salary: {
      name: 'Salary',
      dataType: 'number',
    },
    years: {
      name: 'Years',
      dataType: 'number',
    },
  },
});

export {usersCollection};

import {buildCollection, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {SkillType} from '../../model/skill';

import {skillCollection} from './common';

const collection: Omit<EntityCollection, 'path' | 'properties'> = {
  name: 'Programming languages',
  id: 'programming-languages',
  group: 'General',
  singularName: 'Programming language',
  description: 'JavaScript, Python, Java ...',
  icon: 'Code',
  forceFilter: {
    type: ['==', SkillType.PROGRAMMING_LANGUAGE],
  },
};

const programmingLanguagesCollection = buildCollection(
  merge(collection, skillCollection),
);

export {programmingLanguagesCollection};

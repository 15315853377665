import {
  EntityCollection,
  AsyncPreviewComponent,
  AdditionalFieldDelegate,
} from '@firecms/core';
import {offersCollection} from './offersCollection';
import {usersCollection} from './usersCollection';
import {offerMessagesCollection} from './offerMessagesCollection';
import {first, last, sortBy} from 'lodash';
import {RetryOfferApplicationButton} from '../components/RetryOfferApplicationButton';
import {Link} from '@mui/material';

// builder that will fetch company name from offer.id
export const companyField: AdditionalFieldDelegate = {
  key: 'company',
  name: 'Company',
  Builder: ({entity, context}) => (
    <AsyncPreviewComponent
      builder={context.dataSource
        .fetchEntity({
          path: 'offers',
          entityId: entity.values.offer.id,
          collection: offersCollection,
        })
        .then(entity => entity?.values.company.name)}
    />
  ),
};

// builder that will fetch user email user.id
export const userField: AdditionalFieldDelegate = {
  key: 'user',
  name: 'User',
  Builder: ({entity, context}) => (
    <AsyncPreviewComponent
      builder={context.dataSource
        .fetchEntity({
          path: 'users',
          entityId: entity.values.user.id,
          collection: usersCollection,
        })
        .then(
          entity => entity?.values.fullName + ' (' + entity?.values.email + ')',
        )}
    />
  ),
};

export const lastMessageSenderField: AdditionalFieldDelegate = {
  key: 'lastMessageRecipient',
  name: 'Last Message Recipient',
  Builder: ({entity, context}) => (
    <AsyncPreviewComponent
      builder={context.dataSource
        .fetchCollection({
          path: 'offerMessages',
          collection: offerMessagesCollection,
          filter: {
            offerApplicationId: ['==', entity.id],
          },
        })
        .then(offerMessages => {
          const sortedMessages = sortBy(
            offerMessages,
            'values.createdAt',
            'desc',
          ).filter(msg => msg.values.direction === 'OUTGOING');
          if (sortedMessages.length > 0) {
            const lastMessage = first(sortedMessages);
            return lastMessage?.values?.recipient;
          } else {
            return '--';
          }
        })}
    />
  ),
};

export const lastMessageOpenedField: AdditionalFieldDelegate = {
  key: 'lastMessageOpened',
  name: 'Last Message Opened',
  Builder: ({entity, context}) => (
    <AsyncPreviewComponent
      builder={context.dataSource
        .fetchCollection({
          path: 'offerMessages',
          collection: offerMessagesCollection,
          filter: {
            offerApplicationId: ['==', entity.id],
          },
        })
        .then(offerMessages => {
          const sortedMessages = sortBy(
            offerMessages,
            'values.createdAt',
            'desc',
          ).filter(msg => msg.values.direction === 'OUTGOING');
          if (sortedMessages.length > 0) {
            const lastMessage = first(sortedMessages);
            if (lastMessage?.values.tracking) {
              if (lastMessage?.values.tracking?.opened.length > 1) {
                const lastlyOpened = last(
                  lastMessage?.values?.tracking?.opened as any[],
                ).timestamp as Date;
                // format to DD.MM.YYYY:
                const formatted =
                  lastlyOpened.getDate() +
                  '.' +
                  lastlyOpened.getMonth() +
                  '.' +
                  lastlyOpened.getFullYear();

                return (
                  <div>
                    <span>
                      OPENED {lastMessage?.values.tracking?.opened.length}x
                    </span>
                    <br />
                    <span>Lastly {formatted}</span>
                  </div>
                );
              } else {
                const date1: Date = last(
                  lastMessage?.values.tracking.opened as any[],
                ).timestamp as any;
                const date2: Date = lastMessage?.values.createdAt;
                // time diff
                const diffSeconds = Math.floor(
                  Math.abs(date1.getTime() - date2.getTime()) / 1000,
                );
                if (diffSeconds > 60) {
                  return 'OPENED ONCE';
                } else {
                  return `PROBABLY NOT OPENED (${diffSeconds})s delay`;
                }
              }
            } else {
              return 'NO TRACKING';
            }
          } else {
            return 'NO MESSAGES';
          }
        })}
    />
  ),
};

export const retryFailedApplicationButton: AdditionalFieldDelegate = {
  key: 'retryFailedApplicationButton',
  name: 'Retry',
  Builder: ({entity, context}) =>
    entity.values.status === 'FAILED' ? (
      <RetryOfferApplicationButton
        userId={entity.values.user.id}
        offerApplicationId={entity.id}
      />
    ) : (
      '--'
    ),
};

export const offerLinkField: AdditionalFieldDelegate = {
  key: 'offerLinkField',
  name: 'Offer',
  Builder: ({entity, context}) => (
    <Link
      href={`https://navigara.com/cs/offers/${entity.values.offer.id}`}
      onClick={evt => evt.stopPropagation()}>
      {entity.values.offer.title}
    </Link>
  ),
};

export const offerApplicationsCollection: EntityCollection = {
  id: 'offer_applications',
  name: 'Offer Applications',
  singularName: 'Offer Application',
  path: 'offerApplications',
  collectionGroup: true,
  icon: 'cloud',
  group: 'General',
  permissions: {
    edit: false,
    read: true,
  },
  propertiesOrder: [
    'company',
    'offerLinkField',
    'user',
    'lastMessageOpened',
    'lastMessageRecipient',
    'createdAt',
    'status',
    'statusUpdatedAt',
    'errorMessage',
    'retryFailedApplicationButton',
  ],
  properties: {
    createdAt: {
      editable: false,
      dataType: 'date',
      validation: {
        required: true,
      },
      name: 'Createdat',
    },
    errorMessage: {
      editable: false,
      name: 'Error',
      dataType: 'string',
    },
    status: {
      editable: true,
      dataType: 'string',
      name: 'Status',
      enumValues: [
        {
          id: 'BLOCKED',
          label: 'BLOCKED',
          color: 'grayLight',
        },
        {
          id: 'FAILED',
          label: 'FAILED',
          color: 'redDark',
        },
        {
          label: 'IN CART',
          id: 'IN_CART',
          color: 'yellowDark',
        },
        {
          label: 'INTERESTED',
          id: 'INTERESTED',
        },
        {
          label: 'OFFER',
          id: 'OFFER',
        },
        {
          id: 'WAITING_FOR_RESPONSE',
          label: 'WAITING FOR RESPONSE',
        },
        {
          label: 'WAITING FOR SENDING',
          id: 'WAITING_FOR_SENDING',
          color: 'orangeDark',
        },
      ],
      validation: {
        required: true,
      },
    },
    statusUpdatedAt: {
      editable: false,
      name: 'Statusupdatedat',
      dataType: 'date',
      validation: {
        required: true,
      },
    },
  },
  additionalFields: [
    offerLinkField,
    companyField,
    userField,
    lastMessageOpenedField,
    lastMessageSenderField,
    retryFailedApplicationButton,
  ],
  subcollections: [],
};

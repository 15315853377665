import {buildCollection, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {SkillType} from '../../model/skill';

import {skillCollection} from './common';

const collection: Omit<EntityCollection, 'path' | 'properties'> = {
  name: 'Languages',
  id: 'languages',
  group: 'General',
  singularName: 'Language',
  icon: 'Translate',
  description: 'English, Russian, Czech ...',
  forceFilter: {
    type: ['==', SkillType.LANGUAGE],
  },
};

const languagesCollection = buildCollection(merge(collection, skillCollection));

export {languagesCollection};

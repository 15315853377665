import {buildCollection} from '@firecms/core';
import {Charts} from '../components/Charts';
import {nerSubCollection} from './skills/common';

const citiesCollection = buildCollection<{
  name: string;
  country: string;
  population: number;
  coords: {
    lat: number;
    lng: number;
  };
  coefficients?: [number, number, number, number, number];
}>({
  id: 'cities',
  defaultSize: 'm',
  name: 'Cities',
  singularName: 'City',
  exportable: false,
  selectionEnabled: false,
  path: 'cities',
  description:
    'Cities that displayed on the map https://joblytics.io/. Also this cities being scrapped by our scrappers services',
  icon: 'LocationOn',
  group: 'General',
  permissions: ({authController}) => ({
    edit: false,
  }),
  subcollections: [
    nerSubCollection,
    buildCollection({
      name: 'Charts',
      id: 'charts',
      path: 'charts',
      properties: {
        data: {
          dataType: 'array',
          Preview: Charts,
          of: {
            dataType: 'map',
            properties: {
              name: {
                dataType: 'string',
              },
              value: {
                dataType: 'number',
              },
            },
          },
        },
      },
    }),
  ],
  properties: {
    name: {
      name: 'Name',
      validation: {required: true},
      dataType: 'string',
    },
    country: {
      name: 'Country',
      validation: {required: true},
      dataType: 'string',
    },
    population: {
      name: 'Population',
      validation: {required: true},
      dataType: 'number',
    },
    coefficients: {
      name: 'Coefficients',
      dataType: 'array',
      of: {
        dataType: 'number',
      },
    },
    coords: {
      name: 'Coordinates',
      dataType: 'map',
      properties: {
        lat: {
          name: 'LAT',
          dataType: 'number',
        },
        lng: {
          name: 'LNG',
          dataType: 'number',
        },
      },
    },
  },
});

export {citiesCollection};

import {getFunctions, httpsCallable,} from "firebase/functions";
import { LoadingButton } from "@firecms/ui";
import {MouseEvent, useState} from 'react';
import {initializedFirebaseApp} from '../App';

type Props = {
  userId: string
  offerApplicationId: string
}

export const RetryOfferApplicationButton = ({userId, offerApplicationId}: Props) => {
  const functions = getFunctions(initializedFirebaseApp, 'europe-west1');
  const retryFailedOfferApplication = httpsCallable(functions, "retryFailedOfferApplication");

  const [loading, setLoading] = useState(false)

  const handleClick = async (event: MouseEvent) => {
    // prevent click on table row
    event.stopPropagation()
    try {
      setLoading(true)
      await retryFailedOfferApplication({ userId, offerApplicationId});
      alert('Successful retry');
    } catch (error) {
      // @ts-ignore
      alert(`Error calling function: ${error.message}`);
    } finally {
      setLoading(false)
    }
  };

  return (
    <LoadingButton loading={loading} color="primary" onClick={handleClick}>
      Retry
    </LoadingButton>
  );
};


import {buildCollection, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {SkillType} from '../../model/skill';

import {skillCollection} from './common';

const collection: Omit<EntityCollection, 'path'> = {
  name: 'Other skills',
  id: 'other-skills',
  group: 'General',
  singularName: 'Skill',
  icon: 'LocalOffer',
  description: 'Google Chat, Microsoft Teams, Slack ...',
  properties: {
    type: {
      name: 'Type',
      hideFromCollection: false,
      validation: {required: true},
      dataType: 'string',
      enumValues: SkillType,
    },
  },
  forceFilter: {
    type: [
      'in',
      [
        SkillType.TOOL,
        SkillType.IDE,
        SkillType.ASYNCHRONOUS_TOOL,
        SkillType.SYNCHRONOUS_TOOL,
      ],
    ],
  },
};

const otherSkillsCollection = buildCollection(
  merge(collection, skillCollection),
);

export {otherSkillsCollection};

import {buildCollection, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {SkillType} from '../../model/skill';

import {skillCollection} from './common';

const collection: Omit<EntityCollection, 'path' | 'properties'> = {
  name: 'Positions',
  id: 'positions',
  singularName: 'Position',
  description: 'FrontEnd Developer, Backend Developer ...',
  icon: 'Work',
  forceFilter: {
    type: ['==', SkillType.POSITION],
  },
};

const positionsCollection = buildCollection(merge(collection, skillCollection));

export {positionsCollection};

import {buildCollection, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {skillCollection} from './common';

const collection: Omit<EntityCollection, 'path' | 'properties'> = {
  name: 'Skills',
  group: 'General',
  id: 'skills',
  singularName: 'Skill',
  icon: 'Settings',
  description: 'All skills',
};

const skillsCollection = buildCollection(merge(collection, skillCollection));

export {skillsCollection};

import {buildCollection} from '@firecms/core';

export const companiesCollection = () =>
  buildCollection({
    id: 'companies',
    name: 'Companies',
    path: 'companies',
    editable: false,
    icon: 'adjust',
    group: 'General',
    permissions: {
      edit: false,
    },
    properties: {
      name: {
        dataType: 'string',
        name: 'Name',
        validation: {
          required: true,
        },
      },
      offersCount: {
        dataType: 'number',
        name: 'Offerscount',
      },
      industries: {
        dataType: 'array',
        name: 'Industries',
        of: {
          dataType: 'string',
          name: 'Industries',
        },
      },
      url: {
        dataType: 'string',
        url: true,
        name: 'Url',
        validation: {
          required: true,
        },
      },
      countryCodes: {
        dataType: 'array',
        name: 'Countrycodes',
        of: {
          dataType: 'string',
          enumValues: [
            {
              id: 'AT',
              label: 'AT',
            },
            {
              id: 'BE',
              label: 'BE',
            },
            {
              id: 'BG',
              label: 'BG',
            },
            {
              id: 'BY',
              label: 'BY',
            },
            {
              id: 'CZ',
              label: 'CZ',
            },
            {
              id: 'DE',
              label: 'DE',
            },
            {
              id: 'ES',
              label: 'ES',
            },
            {
              id: 'FI',
              label: 'FI',
            },
            {
              id: 'FR',
              label: 'FR',
            },
            {
              id: 'GB',
              label: 'GB',
            },
            {
              id: 'GR',
              label: 'GR',
            },
            {
              id: 'HU',
              label: 'HU',
            },
            {
              id: 'IT',
              label: 'IT',
            },
            {
              id: 'NL',
              label: 'NL',
            },
            {
              id: 'RO',
              label: 'RO',
            },
            {
              id: 'US',
              label: 'US',
            },
          ],
          name: 'Countrycodes',
        },
      },
      companySize: {
        dataType: 'map',
        name: 'Companysize',
        properties: {
          to: {
            dataType: 'number',
            name: 'To',
          },
          value: {
            dataType: 'string',
            name: 'Value',
          },
          from: {
            dataType: 'number',
            name: 'From',
          },
        },
      },
      companyId: {
        dataType: 'string',
        name: 'Companyid',
        validation: {
          required: true,
        },
      },
      totalOffersCount: {
        dataType: 'number',
        name: 'Totalofferscount',
      },
      averageSalary: {
        dataType: 'number',
        name: 'Averagesalary',
      },
      cities: {
        dataType: 'array',
        name: 'Cities',
        of: {
          dataType: 'map',
          name: 'Cities',
          properties: {
            countryCode: {
              dataType: 'string',
              name: 'Countrycode',
            },
            name: {
              dataType: 'string',
              name: 'Name',
            },
            id: {
              dataType: 'string',
              name: 'Id',
            },
          },
        },
      },
      offersWithSalaryCount: {
        dataType: 'number',
        name: 'Offerswithsalarycount',
      },
    },
    subcollections: [],
  });

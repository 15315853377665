import 'typeface-rubik';
import '@fontsource/jetbrains-mono';
import {
  AppBar,
  Authenticator,
  CircularProgressCenter,
  Drawer,
  EntityCollectionsBuilder,
  FireCMS,
  ModeControllerProvider,
  NavigationRoutes,
  Scaffold,
  SideDialogs,
  SnackbarProvider,
  useBuildLocalConfigurationPersistence,
  useBuildModeController,
  useBuildNavigationController,
  useValidateAuthenticator,
} from '@firecms/core';
import {
  FirebaseAuthController,
  FirebaseLoginView,
  FirebaseSignInProvider,
  FirebaseUserWrapper,
  useFirebaseAuthController,
  useFirebaseStorageSource,
  useFirestoreDelegate,
  useInitialiseFirebase,
} from '@firecms/firebase';

import {firebaseConfig} from './firebase_config';
import {useImportPlugin} from '@firecms/data_import';
import {useExportPlugin} from '@firecms/data_export';
import {useFirestoreCollectionsConfigController} from '@firecms/collection_editor_firebase';
import {useCollectionEditorPlugin} from '@firecms/collection_editor';
import {citiesCollection} from './collections/citiesCollection';
import {cloudPlatformsCollection} from './collections/skills/cloudPlatformsCollection';
import {databasesCollection} from './collections/skills/databasesCollection';
import {languagesCollection} from './collections/skills/languagesCollection';
import {otherSkillsCollection} from './collections/skills/otherCollection';
import {positionsCollection} from './collections/skills/positionsCollection';
import {programmingLanguagesCollection} from './collections/skills/programmingLanguagesCollection';
import {skillsCollection} from './collections/skills/skillsCollection';
import {technologiesCollection} from './collections/skills/technologiesCollection';
import {toolsCollection} from './collections/skills/toolsCollection';
import {usersCollection} from './collections/usersCollection';
import {offerApplicationsCollection} from './collections/offerApplicationsCollection';
import {offerMessagesCollection} from './collections/offerMessagesCollection';
import {companiesCollection} from './collections/companiesCollection';
import {applyStatsCollectionBuilder} from './collections/applyStatsCollection';
import {useCallback} from 'react';
import {FirebaseApp} from '@firebase/app';

export let initializedFirebaseApp: FirebaseApp | undefined;

export const collectionBuilder: EntityCollectionsBuilder = async ({
  dataSource,
  user,
}) => {
  // don't load collection if user is not authenticated
  if (!user?.uid) {
    return [];
  }
  return [
    positionsCollection,
    programmingLanguagesCollection,
    technologiesCollection,
    databasesCollection,
    cloudPlatformsCollection,
    languagesCollection,
    toolsCollection,
    otherSkillsCollection,
    citiesCollection,
    skillsCollection,
    offerApplicationsCollection,
    offerMessagesCollection,
    usersCollection,
    companiesCollection(),
    await applyStatsCollectionBuilder({dataSource}),
  ];
};

export function App() {
  const title = 'Navigara CMS';

  if (!firebaseConfig?.projectId) {
    throw new Error(
      'Firebase config not found. Please check your `firebase_config.ts` file and make sure it is correctly set up.',
    );
  }

  const myAuthenticator: Authenticator<FirebaseUserWrapper> = useCallback(
    async ({user}) => {
      return user?.email === 'cms@navigara.com';
    },
    [],
  );

  const {firebaseApp, firebaseConfigLoading, configError} =
    useInitialiseFirebase({
      firebaseConfig,
    });

  initializedFirebaseApp = firebaseApp;
  /**
   * Controller used to save the collection configuration in Firestore.
   * Note that this is optional and you can define your collections in code.
   */
  const collectionConfigController = useFirestoreCollectionsConfigController({
    firebaseApp,
  });

  const signInOptions: FirebaseSignInProvider[] = ['google.com', 'password'];

  /**
   * Controller used to manage the dark or light color mode
   */
  const modeController = useBuildModeController();

  /**
   * Delegate used for fetching and saving data in Firestore
   */
  const firestoreDelegate = useFirestoreDelegate({
    firebaseApp,
  });

  /**
   * Controller used for saving and fetching files in storage
   */
  const storageSource = useFirebaseStorageSource({
    firebaseApp,
  });

  /**
   * Controller for managing authentication
   */
  const authController: FirebaseAuthController = useFirebaseAuthController({
    firebaseApp,
    signInOptions,
  });

  /**
   * Controller for saving some user preferences locally.
   */
  const userConfigPersistence = useBuildLocalConfigurationPersistence();

  /**
   * Use the authenticator to control access to the main view
   */
  const {authLoading, canAccessMainView, notAllowedError} =
    useValidateAuthenticator({
      authController,
      dataSourceDelegate: firestoreDelegate,
      storageSource,
      authenticator: myAuthenticator,
    });

  const navigationController = useBuildNavigationController({
    collections: collectionBuilder,
    collectionPermissions: () => ({
      read: true,
      // todo - for now
      create: false,
      edit: false,
      delete: false,
    }),
    authController,
    dataSourceDelegate: firestoreDelegate,
  });

  /**
   * Allow import and export data plugin
   */
  const importPlugin = useImportPlugin();
  const exportPlugin = useExportPlugin();

  const collectionEditorPlugin = useCollectionEditorPlugin({
    collectionConfigController,
  });

  if (firebaseConfigLoading || !firebaseApp) {
    return <CircularProgressCenter />;
  }

  if (configError) {
    return <>{configError}</>;
  }

  return (
    <SnackbarProvider>
      <ModeControllerProvider value={modeController}>
        <FireCMS
          apiKey={'fcms_t7lc7dc86fanti62zkd0ggaj8xlbms1mae23wl3boj8'}
          navigationController={navigationController}
          authController={authController}
          userConfigPersistence={userConfigPersistence}
          dataSourceDelegate={firestoreDelegate}
          storageSource={storageSource}
          plugins={[importPlugin, exportPlugin, collectionEditorPlugin]}>
          {({loading}) => {
            let component;
            if (loading || authLoading) {
              component = <CircularProgressCenter size={'large'} />;
            } else {
              if (!canAccessMainView) {
                component = (
                  <FirebaseLoginView
                    allowSkipLogin={false}
                    signInOptions={signInOptions}
                    firebaseApp={firebaseApp}
                    authController={authController}
                    notAllowedError={notAllowedError}
                  />
                );
              } else {
                component = (
                  <Scaffold autoOpenDrawer={false}>
                    <AppBar title={title} />
                    <Drawer />
                    <NavigationRoutes />
                    <SideDialogs />
                  </Scaffold>
                );
              }
            }

            return component;
          }}
        </FireCMS>
      </ModeControllerProvider>
    </SnackbarProvider>
  );
}

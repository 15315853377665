import {EntityCollection} from '@firecms/core';

export const offerMessagesCollection: EntityCollection = {
  id: 'offer_messages',
  name: 'Offer Messages',
  singularName: 'offerMessage',
  path: 'offerMessages',
  editable: false,
  collectionGroup: true,
  icon: 'calendar_view_month',
  group: 'General',
  permissions: {
    edit: false,
  },
  properties: {
    attachments: {
      dataType: 'array',
      of: {
        name: 'Attachments',
        editable: true,
        properties: {
          contentType: {
            name: 'Contenttype',
            editable: true,
            dataType: 'string',
          },
          size: {
            editable: true,
            dataType: 'number',
            name: 'Size',
          },
          name: {
            dataType: 'string',
            editable: true,
            name: 'Name',
          },
          storagePath: {
            dataType: 'string',
            name: 'Storagepath',
            editable: true,
          },
        },
        dataType: 'map',
      },
      name: 'Attachments',
    },
    bodyPlain: {
      dataType: 'string',
      name: 'Bodyplain',
    },
    cc: {
      dataType: 'string',
      name: 'Cc',
    },
    createdAt: {
      dataType: 'date',
      name: 'Createdat',
      validation: {
        required: true,
      },
    },
    direction: {
      name: 'Direction',
      dataType: 'string',
      enumValues: [
        {
          label: 'INCOMING',
          id: 'INCOMING',
        },
        {
          label: 'OUTGOING',
          id: 'OUTGOING',
        },
      ],
      validation: {
        required: true,
      },
    },
    domain: {
      dataType: 'string',
      name: 'Domain',
      enumValues: [
        {
          label: 'Mail.navigara.com',
          id: 'mail.navigara.com',
        },
        {
          id: 'mg.joblytics.io',
          label: 'Mg.joblytics.io',
        },
      ],
    },
    from: {
      name: 'From',
      validation: {
        required: true,
      },
      dataType: 'string',
    },
    messageHeaders: {
      name: 'Messageheaders',
      dataType: 'map',
      properties: {
        Received: {
          name: 'Received',
          editable: true,
          dataType: 'string',
        },
        From: {
          name: 'From',
          dataType: 'string',
          editable: true,
        },
        To: {
          editable: true,
          name: 'To',
          dataType: 'string',
        },
        Subject: {
          name: 'Subject',
          dataType: 'string',
          editable: true,
        },
        Date: {
          name: 'Date',
          dataType: 'string',
          editable: true,
        },
        'Message-Id': {
          name: 'Message Id',
          editable: true,
          dataType: 'string',
        },
      },
    },
    messageId: {
      name: 'Messageid',
      dataType: 'string',
      validation: {
        required: true,
      },
    },
    messageUrl: {
      name: 'Messageurl',
      dataType: 'string',
    },
    recipient: {
      validation: {
        required: true,
      },
      email: true,
      name: 'Recipient',
      dataType: 'string',
    },
    sender: {
      name: 'Sender',
      dataType: 'string',
      validation: {
        required: true,
      },
      email: true,
    },
    subject: {
      validation: {
        required: true,
      },
      name: 'Subject',
      dataType: 'string',
    },
    timestamp: {
      dataType: 'number',
      name: 'Timestamp',
    },
    tracking: {
      dataType: 'map',
      name: 'Tracking',
      properties: {
        opened: {
          editable: true,
          of: {
            dataType: 'map',
            editable: true,
            properties: {
              timestamp: {
                editable: true,
                dataType: 'date',
                name: 'Timestamp',
              },
              ip: {
                name: 'Ip',
                editable: true,
                dataType: 'string',
              },
              eventId: {
                name: 'Eventid',
                editable: true,
                dataType: 'string',
              },
            },
            name: 'Opened',
          },
          dataType: 'array',
          name: 'Opened',
        },
      },
    },
  },
  subcollections: [],
};

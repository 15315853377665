import {buildCollection, EntityCollection} from '@firecms/core';
import {merge} from 'lodash';

import {SkillType} from '../../model/skill';

import {skillCollection} from './common';

const collection: Omit<EntityCollection, 'path' | 'properties'> = {
  name: 'Cloud platforms',
  id: 'cloud-platforms',
  group: 'General',
  singularName: 'Cloud platform',
  icon: 'Cloud',
  description: 'Azure, Google Cloud, AWS ...',
  forceFilter: {
    type: ['==', SkillType.CLOUD_PLATFORM],
  },
};

const cloudPlatformsCollection = buildCollection(
  merge(collection, skillCollection),
);

export {cloudPlatformsCollection};
